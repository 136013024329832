html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.dark .message.in a {
  color: #4D8EFF;
}
.light .message.in a {
  color: blue;
}

.ai-response {
  white-space: pre-wrap;
  margin-top: 0px;
  margin-bottom: 0px;
}

.dark .ai-response pre {
  background: #1b1b1b;
  color: #72e0d1;
  border: 1px solid #666;
  border-left: 3px solid #72e0d1;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.light .ai-response pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.ai-response > p {
  margin-block-end: 0px;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  background-color: transparent; /* Change the background-color of table here */
  text-align: left; /* Change the text-alignment of table here */
}

 th {
  font-weight: bold;
  border: 1px solid #cccccc; /* Change the border-color of heading here */
  padding: 8px;
}

td {
  border: 1px solid #cccccc; /* Change the border-color of cells here */
  padding: 8px;
}

.ai-response-content-container:hover:not(:has(.ai-response-code-container:hover,.copy-code-button:hover)) > .copy-content-button {
  display:block;
  z-index:2
} 

.ai-response-code-container:hover ~ .copy-code-button{
  display:block;
}

.ai-response-code-container ~ .copy-code-button{
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
}

.copy-code-button:hover{
  display: block;
}

.ai-response-code-container ~ .copy-content-button:hover {
  display: block;
}

.copy-content-button {
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
}

.model-selection-button:hover {
  cursor: pointer;
}

.model-selector-container div:first-of-type {
  margin-left: auto;
}

/* .model-selector-container div:last-of-type,
.model-selector-container button:last-of-type {
  margin-right:auto;
} */

.model-selector-container :last-child{
  margin-right:auto;
}

.prompt:focus {
  outline: none;
}

.prompt {
  position: relative;
  border-radius: 24px;
  padding: 8px 54px 8px 16px;
}

.light .prompt {
  border: 2px solid #dbe4f3;
  background-color: #f7faff;
}

.dark .prompt {
  border: 2px solid black;
  background-color: #292929;
}

.prompt.empty::after {
  content: attr(data-placeholder);
  color: #717376;
  position: absolute;
  left: 16px;
  top: 8px;
  user-select: none;
}

.prompt span[data-mention-id] {
  user-select: none !important;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.8em;
  font-weight: 900;
  box-decoration-break: clone;
}

.prompt {
  caret-color: #6598ef;
}

.light .prompt span[data-mention-id] {
  background-color: #e7eeff;
  color: #254381;
}

.dark .prompt span[data-mention-id] {
  background-color: #121212;
  color: white;
}

.message span[data-mention-id] {
  border-radius: 4px;
  box-decoration-break: clone;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
}
.light .message span[data-mention-id] {
  color: #003CA5;
  background-color: #E7EFFF;
  padding: 8px;
}

.dark .message span[data-mention-id] {
  color: white;
  background-color: #292929;
  padding: 8px;
}


/**
 * Rich text snippet styling 
 */

 pre[data-snippet-type] {
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  max-height: 300px;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.light pre[data-snippet-type] {
  background: white;
  color: #666;
  border: 1px solid #ddd;
  border-left: 3px solid #6a86e5;
}

.dark pre[data-snippet-type] {
  background: #1b1b1b;
  color: white;
  border: 1px solid #666;
  border-left: 3px solid #9db4ff;
}

/**
 * MS Word table styling (general)
 */

pre[data-snippet-type="ms-word-table"] table {
  white-space-collapse: initial;
}

pre[data-snippet-type="ms-word-table"] td p {
  margin: 0
}

/**
 * MS Word table styling (light theme)
 */

.light pre[data-snippet-type="ms-word-table"] table {
  background: white;
}

.light pre[data-snippet-type="ms-word-table"] table tr:nth-child(odd) {
  background: #eee;
}

.light pre[data-snippet-type="ms-word-table"] table tr td {
  border-color: #9ba7ce;
}

/**
 * MS Word table styling (dark theme)
 */

.dark pre[data-snippet-type="ms-word-table"] table {
  background: #222;
}

.dark pre[data-snippet-type="ms-word-table"] table tr:nth-child(odd) {
  background: #333;
}

.dark pre[data-snippet-type="ms-word-table"] table tr td {
  border-color: #7d8fca;
}

